/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    unindent: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M13 8a.5.5 0 00-.5-.5H5.707l2.147-2.146a.5.5 0 10-.708-.708l-3 3a.5.5 0 000 .708l3 3a.5.5 0 00.708-.708L5.707 8.5H12.5A.5.5 0 0013 8"/><path pid="1" fill-rule="evenodd" d="M3.5 4a.5.5 0 00-.5.5v7a.5.5 0 001 0v-7a.5.5 0 00-.5-.5"/>',
    },
});
